// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-of-conduct-js": () => import("../src/pages/codeOfConduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-games-tsx": () => import("../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-js": () => import("../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-panels-js": () => import("../src/pages/panels.js" /* webpackChunkName: "component---src-pages-panels-js" */),
  "component---src-pages-schedule-js": () => import("../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-sponsors-tsx": () => import("../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */)
}

